import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import { FieldTextInput, LocationAutocompleteInput } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './DateFilter.module.css';
import { Field } from 'react-final-form';
import config from '../../../config';
import { isOriginInUse } from '../../../util/search';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { TextField } from '@material-ui/core';
import moment from 'moment';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
const identity = (v) => v;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = (queryParamNames) => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'keywords';
};

class DateFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
    // Callback ref
    this.fieldRef = React.createRef();
    this.startRef = React.createRef();
    this.endRef = React.createRef();
    this.searchInput = React.createRef();
    this.setSearchInputRef = (element) => {
      this.searchInput.current = element;
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      handleLocationClear,
      selEndDateMaybe,
      selStartDateMaybe,
      values,
      handleDateClear,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const urlParam = getKeywordQueryParam(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'KeywordFilter.labelSelected' },
          { labelText: initialValues[urlParam] }
        )
      : label;

    const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
    const labelForPlain = <span className={labelClass}>{label}</span>;

    const filterText = intl.formatMessage({ id: 'KeywordFilter.filterText' });

    const placeholder = intl.formatMessage({ id: 'KeywordFilter.placeholder' });

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: initialValues[urlParam] };
    const handleSubmit = (values) => {
      const usedAddress = values ? values.address : values;
      const usedBounds = values ? values.bounds : values;
      onSubmit({ address: usedAddress, bounds: usedBounds });
    };

    const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
      leading: false,
      trailing: true,
    });
    // Use timeout for shart queries and debounce for queries with any length
    const handleChangeWithDebounce = (location) => {
      const topbarSearchParams = () => {
        // topbar search defaults to 'location' search
        const { search, selectedPlace = null } = location;
        const { origin, bounds } = selectedPlace || {};
        const originMaybe = isOriginInUse(config) ? { origin } : {};
        return {
          ...originMaybe,
          address: search,
          bounds,
        };
      };
      if (location) {
        handleSubmit(topbarSearchParams());
      }
    };
    const handleOnChange = (e) => {
      const value = e.target.value;
      const id = e.target.id;
      const unixTs = moment(value, 'YYYYMMDD').unix();
      if (id === 'startbst') {
        if (namedInitialValues[name]) {
          const newQuery = `${unixTs},${namedInitialValues[name].split(',')[1]}`;
          return onSubmit({ pub_businessEstDateSearch: newQuery });
        } else {
          return onSubmit({ pub_businessEstDateSearch: `${unixTs},` });
        }
      } else if (id === 'endbst') {
        if (namedInitialValues[name]) {
          const newQuery = `${namedInitialValues[name].split(',')[0]},${unixTs}`;
          return onSubmit({ pub_businessEstDateSearch: newQuery });
        } else {
          return onSubmit({ pub_businessEstDateSearch: `,${unixTs}` });
        }
      }
    };
    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      if (this.startRef && this.startRef.current) {
        this.startRef.current.value = null;
      }
      if (this.endRef && this.endRef.current) {
        this.endRef.current.value = null;
      }
      handleDateClear();
    };
    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <FieldTextInput
          className={css.field}
          name={name}
          id={`${id}-input`}
          type="text"
          label={filterText}
          placeholder={placeholder}
          autoComplete="off"
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleChangeWithDebounce}
        onClear={handleClear}
        initialValues={namedInitialValues}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>
          <label className={css.mxprlbl}>Starting Date</label>
          <input
            className={classNames(css.businessdte, {
              [css.phinp]: selStartDateMaybe === null || typeof selStartDateMaybe === 'undefined',
            })}
            id="startbst"
            type="date"
            ref={this.startRef}
            value={selStartDateMaybe?.replace(/\//g, '-')}
            // placeholder="DD-MM-YYYY"
            // defaultValue="DD-MM-YYYY"
            //   value={values.businessEstDate}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // value={}
            // min={new Date().toISOString().split('T')[0]}
            onChange={handleOnChange}
            max={
              selEndDateMaybe
                ? moment(selEndDateMaybe).subtract(1, 'day').toDate().toISOString().split('T')[0]
                : null
            }
          />
          <label className={css.mnprlbl}>Ending Date</label>
          <input
            className={classNames(`${css.scnd} ${css.businessdte}`, {
              [css.phinp]: selEndDateMaybe === null || typeof selEndDateMaybe === 'undefined',
            })}
            id="endbst"
            ref={this.endRef}
            type="date"
            value={selEndDateMaybe?.replace(/\//g, '-')}
            // defaultValue="Enter date"
            //   value={values.businessEstDate}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={handleOnChange}
            min={
              selStartDateMaybe
                ? moment(selStartDateMaybe).add(2, 'days').toDate().toISOString().split('T')[0]
                : null
            }
          />
        </fieldset>
      </FilterPlain>
    );
  }
}

DateFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

DateFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(DateFilter);

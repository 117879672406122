import React from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { PaginationLinks } from '../../../components';

import css from './SearchResultsPanel.module.css';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import SearchPageListingCard from '../../../components/SearchPageListingCard/SearchPageListingCard';
const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const SearchResultsPanel = (props) => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    history,
    isMapVariant,
    intl,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;
  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {listings?.length > 0 ? (
          listings.map((l) => {
            const price = l?.attributes?.price;
            const title = l?.attributes?.title;
            const id = l?.id?.uuid;
            const { formattedPrice, priceTitle } = priceData(price, intl);
            const isFavourite =
              !!currentUser?.attributes?.profile.privateData?.favourites?.includes(id);

            return (
              <SearchPageListingCard
                key={id}
                formattedPrice={formattedPrice}
                l={l}
                isFavourite={isFavourite}
                title={title}
                id={id}
                history={history}
                isVerified={!!l?.attributes?.metadata?.isVerified}
                currentUser={currentUser}
              />
            );
          })
        ) : (
          <h2>No Results Found</h2>
        )}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;

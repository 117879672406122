import axios from 'axios';
import React, { useState } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import routeConfiguration from '../../routing/routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import { createResourceLocatorString } from '../../util/routes';
import css from './SearchPageListingCard.module.css';
import { BsCheckCircleFill } from 'react-icons/bs';
import { ReactComponent as Cut } from '../../assets/cut.svg';
import { compose } from 'redux';
import { connect } from 'react-redux';
import IconReviewStar from '../IconReviewStar/IconReviewStar';
const SearchPageListingCard = (props) => {
  const {
    formattedPrice,
    l,
    title,
    id,
    history,
    fromFav,
    toggleFavouritesButton,
    isVerified,
    isAuthenticated,
    currentUser,
  } = props;
  const [isFavourite, setIsFavourite] = useState(props.isFavourite);
  const [saving, setSaving] = useState(false);
  const image = l?.images[0]?.attributes?.variants['listing-card'].url;
  const imageHover = l?.images[1]?.attributes?.variants['listing-card']?.url || image;
  const isOwnListing = l?.author?.id?.uuid === currentUser?.id?.uuid;
  // console.log(147, l, currentUser, isOwnListing);
  const over = (e) => {
    e.currentTarget.src = imageHover;
  };
  const out = (e) => {
    e.currentTarget.src = image;
  };
  const { rating } = l?.attributes?.metadata || {};

  return (
    <div className={css.cstmcrd} key={id}>
      {/* {!isVerified ? (
        <span className={css.verif}>
          {' '}
          <Cut />
          Un-Verified{' '} 
        </span>
      ) : (
        <span className={css.verif}>
          {' '}
          <BsCheckCircleFill />
          Verified{' '}
        </span>
      )} */}
      {!isOwnListing ? (
        <span
          className={css.tagspan}
          onClick={(e) => {
            if (!isAuthenticated) {
              return history.push(
                createResourceLocatorString('LoginPage', routeConfiguration(), {}, {})
              );
            }
            e.stopPropagation();
            if (fromFav) {
              setSaving(true);
              toggleFavouritesButton(l.id.uuid);
              setSaving(false);
            } else {
              setSaving(true);
              const url = `${apiBaseUrl()}/api/toggleFavourites`;
              const data = { listingId: id };
              axios.post(url, data, { withCredentials: true }).then(() => {
                setSaving(false);
                setIsFavourite(!isFavourite);
              });
            }
          }}
        >
          {' '}
          {isFavourite ? <AiFillStar /> : <AiOutlineStar />}
          {saving ? 'Saving...' : isFavourite ? 'Saved' : 'Save'}
        </span>
      ) : null}

      <img src={image} alt="" onMouseOver={over} onMouseOut={out} />
      <p className={css.titleRow}>
        {title}

        {typeof rating !== 'undefined' ? (
          <span className={css.rtedv}>
            {[1, 2, 3, 4, 5].map((star) => (
              <IconReviewStar key={`star-${star}`} isFilled={star <= rating} />
            ))}
          </span>
        ) : (
          <span className={css.rtedv}>
            {[1, 2, 3, 4, 5].map((star) => (
              <IconReviewStar key={`star-${star}`} />
            ))}
          </span>
        )}
      </p>
      <p className={css.addressLine}>
        {l?.attributes?.publicData?.address.length > 50
          ? `${l?.attributes?.publicData?.address.slice(0, 50)}...`
          : l?.attributes?.publicData?.address}
      </p>
      <span className={css.price}>{formattedPrice}</span>
      <button
        className={css.buttonDetail}
        onClick={() =>
          history.push(
            createResourceLocatorString(
              'ListingPage',
              routeConfiguration(),
              { slug: encodeURI(title), id },
              {}
            )
          )
        }
      >
        View Details
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated,
  };
};
export default compose(connect(mapStateToProps))(SearchPageListingCard);

import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import {
  FieldTextInput,
  LocationAutocompleteInput,
  LocationAutocompleteInputField,
} from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './LocationFilter.module.css';
import { Field } from 'react-final-form';
import config from '../../../config';
import { isOriginInUse } from '../../../util/search';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
const identity = (v) => v;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = (queryParamNames) => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'keywords';
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
    // Callback ref
    this.fieldRef = React.createRef();
    this.searchInput = React.createRef();
    this.setSearchInputRef = (element) => {
      this.searchInput.current = element;
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      handleLocationClear,
      urlQueryParams,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const urlParam = getKeywordQueryParam(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'KeywordFilter.labelSelected' },
          { labelText: initialValues[urlParam] }
        )
      : label;

    const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
    const labelForPlain = <span className={labelClass}>{label}</span>;

    const filterText = intl.formatMessage({ id: 'KeywordFilter.filterText' });

    const placeholder = intl.formatMessage({ id: 'KeywordFilter.placeholder' });

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field

    //CHECK
    const namedInitialValues = { [name]: initialValues[urlParam] };
    const valueForSearch = urlQueryParams?.address
      ? {
          search: urlQueryParams.address,
          selectedPlace: {
            ...(urlQueryParams?.address ? { address: urlQueryParams.address } : {}),
            ...(urlQueryParams?.bounds ? { bounds: urlQueryParams.bounds } : {}),
          },
        }
      : { search: '', selectedPlace: null };
    const handleSubmit = (values) => {
      const usedAddress = values ? values.address : values;
      const usedBounds = values ? values.bounds : values;

      onSubmit({ address: usedAddress, bounds: usedBounds });
    };

    const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
      leading: false,
      trailing: true,
    });
    // Use timeout for shart queries and debounce for queries with any length
    const handleChangeWithDebounce = (value) => {
      const { location } = value;
      const topbarSearchParams = () => {
        // topbar search defaults to 'location' search
        const { search, selectedPlace = null } = location;
        const { origin, bounds } = selectedPlace || {};
        const originMaybe = isOriginInUse(config) ? { origin } : {};

        return {
          ...originMaybe,
          address: search,
          ...(bounds ? { bounds } : {}),
        };
      };

      if (location) {
        handleSubmit(topbarSearchParams());
      }
    };

    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      if (this.searchInput && this.searchInput.current) {
        this.searchInput.current.value = null;
        this.searchInput.current.type = '';
        this.searchInput.current.type = 'text';
      }
      setTimeout(() => {
        handleLocationClear();
      }, 200);
    };

    const locationAutoCompleteMaybe = (
      <LocationAutocompleteInputField
        className={false ? css.mobileInputRoot : css.desktopInputRootClass}
        iconClassName={false ? css.mobileIcon : css.desktopIcon}
        inputClassName={false ? css.mobileInput : css.desktopInput}
        predictionsClassName={false ? css.mobilePredictions : css.desktopPredictions}
        name="location"
        placeholder={'E.g. - Kolkata'}
        // meta={meta}
        format={identity}
        // initialValues={valueForSearch}
        // valueFromForm={valueForSearch}
        closeOnBlur={!false}
        // input={value}
        inputRef={(node) => {
          this.searchInput.current = node;
        }}
      />
    );
    // input={inputProps}
    // validClassName={css.validLocation}
    // autoFocus={autoFocus}
    // label={titleRequiredMessage}
    // useDefaultPredictions={false}
    // validate={composeValidators(
    //   autocompleteSearchRequired(addressRequiredMessage),
    //   autocompletePlaceSelected(addressNotRecognizedMessage)
    // )}
    // <Field
    //   name="location"
    //   format={identity}
    //   // ref={this.searchInput}
    //   initialValue={valueForSearch}
    //   render={(fieldProps) => {
    //     const { input, meta } = fieldProps;
    //     const { onChange, ...restInput } = input;
    //     // Merge the standard onChange function with custom behaviur. A better solution would
    //     // be to use the FormSpy component from Final Form and pass this.onChange to the
    //     // onChange prop but that breaks due to insufficient subscription handling.
    //     // See: https://github.com/final-form/react-final-form/issues/159
    //     const searchOnChange = (value) => {
    //       onChange(value);
    //       // this.searchInput.current.value = value.search;
    //       handleChangeWithDebounce(value);
    //     };
    //     const inputProps = false
    //       ? {
    //           ...restInput,
    //           onChange: searchOnChange,
    //           // value: valueForSearch,
    //           // ...(valueForSearch ? { value: valueForSearch } : {}),
    //         }
    //       : {
    //           ...restInput,
    //           onChange: searchOnChange,
    //           // value: valueForSearch,
    //           // ...(valueForSearch ? { value: valueForSearch } : {}),
    //         };
    //     return (
    //       // <LocationAutocompleteInputField
    //       //   className={false ? css.mobileInputRoot : css.desktopInputRootClass}
    //       //   iconClassName={false ? css.mobileIcon : css.desktopIcon}
    //       //   inputClassName={false ? css.mobileInput : css.desktopInput}
    //       //   predictionsClassName={false ? css.mobilePredictions : css.desktopPredictions}
    //       //   name="location"
    //       //   placeholder={'E.g. - Kolkata'}
    //       //   meta={meta}
    //       //   format={identity}
    //       //   // valueFromForm={values.location}
    //       //   closeOnBlur={!false}
    //       //   inputRef={(node) => {
    //       //     this.searchInput.current = node;
    //       //   }}
    //       //   input={inputProps}
    //       //   // validClassName={css.validLocation}
    //       //   // autoFocus={autoFocus}
    //       //   // label={titleRequiredMessage}
    //       //   // useDefaultPredictions={false}
    //       //   // validate={composeValidators(
    //       //   //   autocompleteSearchRequired(addressRequiredMessage),
    //       //   //   autocompletePlaceSelected(addressNotRecognizedMessage)
    //       //   // )}
    //       // />

    //       <LocationAutocompleteInput
    //         className={false ? css.mobileInputRoot : css.desktopInputRootClass}
    //         iconClassName={false ? css.mobileIcon : css.desktopIcon}
    //         inputClassName={false ? css.mobileInput : css.desktopInput}
    //         predictionsClassName={false ? css.mobilePredictions : css.desktopPredictions}
    //         predictionsAttributionClassName={false ? css.mobilePredictionsAttribution : null}
    //         placeholder={'E.g. - Kolkata'}
    //         closeOnBlur={!false}
    //         inputRef={(node) => {
    //           this.searchInput.current = node;
    //         }}
    //         input={inputProps}
    //         meta={meta}
    //         valueFromForm={valueForSearch}
    //       />
    //     );
    //   }}
    // />
    // );
    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <FieldTextInput
          className={css.field}
          name={name}
          id={`${id}-input`}
          type="text"
          label={filterText}
          placeholder={placeholder}
          autoComplete="off"
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleChangeWithDebounce}
        onClear={handleClear}
        initialValues={valueForSearch}
        iSFromLocationFilter={true}
        // keepDirtyOnReinitialize={true}
        locationAutoCompleteMaybe={locationAutoCompleteMaybe}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>
          <label className={css.fieldPlainLabel}>{'Enter Location'}</label>
          {locationAutoCompleteMaybe}
        </fieldset>
      </FilterPlain>
    );
  }
}

LocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

LocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilter);
